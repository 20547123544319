
import styles from './Settings.module.css'
import { useState, useEffect } from "react";
import { useUnit } from "effector-react";
import { addressStore } from "../Store/ConfigStore";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Settings(props) {
    const [ageList] = useState([...Array(100)].map((_, i) => i));
    const [monthList] = useState([...Array(12)].map((_, i) => i));
    const [defectList, setDefectList] = useState([]);
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const address = useUnit(addressStore);
    const [isValid, setIsValid] = useState(true)
    const [age, setAge] = useState(0);
    const [month, setMonth] = useState(0);
    const [note, setNote] = useState("")
    const [value, setValue] = useState(0);
    const [isDownloadFile, setIsDownloadFile] = useState(false);

    function chengeValue(event) {
        setValue(event.target.value);
    }

    useEffect(() => {
        if (!((sessionStorage.getItem("token") !== "" && sessionStorage.getItem("token") !== null && sessionStorage.getItem("token") !== undefined))) {
            navigate('/')
        } else {
            axios.get(address + `patient/check?r=` + searchParams.get('r'))
                .then(res => {
                    if (res.status === 200) {
                        setIsValid(true)
                        axios.post(address + `mod/patient/get`, {
                            "Id": +searchParams.get('r'),
                        })
                            .then(res => {
                                if (res.status === 200) {
                                    setAge(Math.trunc(res["data"]["Age"]))
                                    setMonth(getMonth(res["data"]["Age"]))
                                    setNote(res["data"]["Notes"])
                                    setValue(res["data"]["Mode"])
                                }
                            }).catch((error) => { });

                        axios.get(address + `mod/defects/all`)
                            .then(res => {
                                if (res.status === 200) {
                                    let mem = [...res["data"]];
                                    mem = mem.map((x, i) => { return { Name: x["Name"], Chacked: false, "Id": x["Id"] } })
                                    axios.post(address + `mod/patient/defects/list`, {
                                        "Id": +searchParams.get('r'),
                                    })
                                        .then(res2 => {
                                            if (res2.status === 200) {
                                                let mem2 = [...res2["data"]]
                                                console.log([...res2["data"]])
                                                mem2.map((x) => {
                                                    for (let j = 0; j < mem.length; j++)
                                                        if (mem[j]["Id"] === x["Id"])
                                                            mem[j]["Chacked"] = true;
                                                })
                                                setDefectList(mem)
                                            }
                                        }).catch((error) => { });

                                }
                            }).catch((error) => { });


                    }
                }).catch((error) => {
                    setIsValid(false)
                });
        }

    }, [])

    const saveHandler = () => {
        setIsDownloadFile(true)
        axios.post(address + `mod/patient/update`, {
            "Id": +searchParams.get('r'),
            "Age": +age + +(month / 12),
            "Notes": note,
            "Mode": +value
        })
            .then(res => {
                if (res.status === 200) {setIsDownloadFile(false) }
            }).catch((error) => { alert("Ошибка сохранения"); setIsDownloadFile(false) });
    }

    const checkHandler = (x, i) => {
        let mem = [...defectList];
        if (!mem[i]["Chacked"]) {
            axios.post(address + `mod/patient/defects/add`, {
                "Id": +searchParams.get('r'),
                "DefectId": x["Id"]
            })
                .then(res => {
                    if (res.status === 200) { }
                }).catch((error) => { alert("Ошибка добавления дефекта") });
        } else {
            axios.post(address + `mod/patient/defects/delete`, {
                "Id": +searchParams.get('r'),
                "DefectId": x["Id"]
            })
                .then(res => {
                    if (res.status === 200) { }
                }).catch((error) => { alert("Ошибка удаления дефекта") });
        }
        mem[i]["Chacked"] = !mem[i]["Chacked"];
        setDefectList(mem);
    }

    return (
        <div>
            {isValid &&
                <div>
                    <div className={styles.shadowBox} style={!isDownloadFile ? { display: 'none' } : {}}>
                        <div className={styles.sendText}>
                            Происходит сохранение...
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} />
                        <div className={styles.buttonCreateLink2} onClick={() => {
                            navigate('/link_page')
                        }}>
                            Назад
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div >
                            <div className={styles.textAge}>
                                <span title="Живите долго и процветайте!"> выберите возраст</span>
                            </div>
                            <div className={styles.wrapperAge} style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={styles.textAge}>
                                    Количество лет
                                </div>
                                <div style={{ marginLeft: 5 }}>
                                    <select style={{ height: 20, width: 50 }} value={age}
                                        onChange={e => setAge(e.target.value)}>
                                        {ageList.map((x, i) => {
                                            return (
                                                <option key={i} className={styles.selectNameElement} >{x}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div style={{ marginLeft: 5 }} className={styles.textAge}>месяцев</div>
                                <div style={{ marginLeft: 5 }}>
                                    <select style={{ height: 20, width: 50 }} value={month}
                                        onChange={e => setMonth(e.target.value)}>
                                        {monthList.map((x, i) => {
                                            return (
                                                <option key={i} className={styles.selectNameElement} >{x}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className={styles.textAge} style={{ marginTop: 30, marginBottom: 10 }}>
                                выберите дефект/дефекты
                            </div>
                            {defectList.map((x, i) => {
                                return (
                                    <div key={i}>
                                        <input style={{ cursor: 'pointer' }} type="checkbox" checked={x["Chacked"]} onChange={() => checkHandler(x, i)} /> <span>{x["Name"]}</span>
                                    </div>
                                )
                            })}

                            <div style={{ marginTop: 40 }}>
                                <div>Выберите режим записи фраз</div>
                                <div style={{ marginTop: 10 }}>
                                    <input type="radio" name="radio" value="0" style={{ cursor: 'pointer' }}
                                        checked={value == '0' ? true : false}
                                        onChange={chengeValue} />
                                    <span style={{ marginLeft: 10 }}>Режим полного тестирования</span>
                                </div>
                                <div>
                                    <input type="radio" name="radio" value="1" style={{ cursor: 'pointer' }}
                                        checked={value == '1' ? true : false}
                                        onChange={chengeValue} />
                                    <span style={{ marginLeft: 10 }}>Режим выдачи случайных слов</span>
                                </div>
                                <div>
                                    <input type="radio" name="radio" value="2" style={{ cursor: 'pointer' }}
                                        checked={value == '2' ? true : false}
                                        onChange={chengeValue} />
                                    <span style={{ marginLeft: 10 }}>Режим выдачи проблемных слов в <br /> соответствии с выбранными дефектами</span>
                                </div>
                            </div>
                            <div style={{ marginTop: 50 }}>
                                <textarea placeholder="Для записи примечаний. (не вводить личные данные, идентифицирующие пользователей)" value={note}
                                    onChange={e => setNote(e.target.value)}
                                    className={styles.textAreaStyle}>
                                </textarea>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                <div className={styles.buttonCreateLink2} style={{  margin: 0 }} onClick={() => {
                                    saveHandler()
                                }}>
                                    Сохранить
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!isValid &&
                <div className={styles.notText}>
                    Такой ссылки не существует
                </div>
            }
        </div>
    );
}

function getMonth(num) {
    let res = 0;
    let drob = (num - Math.trunc(num)) * 1.2;
    for (let i = 0; i <= 1.1; i += 0.1)
        if (Math.abs(i - drob) < 0.05) {
            res = Math.round(i * 10);
            break;
        }
    return res;
}

export default Settings;