
import styles from './CollectorPage.module.css'
import { useState, useEffect } from "react";
import { useUnit } from "effector-react";
import { addressStore } from "../Store/ConfigStore";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import AudioAnalyser from "react-audio-analyser";

function CollectorPage(props) {
    let navigate = useNavigate();
    const [session, setSession] = useState(generateRandomString(8))
    const [age] = useState("-");
    const [searchParams] = useSearchParams();
    const address = useUnit(addressStore);
    const [oldWords, setOldWords] = useState([]);
    const [words, setWords] = useState([]);
    const [word, setWord] = useState("");
    const [currentWordID, setCurrentWordID] = useState(0);
    const [isValid, setIsValid] = useState(true);
    const [isRecording, setIsRecording] = useState(false);
    const [blob, setBlob] = useState("");
    const [status, setStatus] = useState("");
    const [audioType] = useState("audio/wav");
    const [audioSrc, setAudioSrc] = useState("");
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [isDownloadFile, setIsDownloadFile] = useState(false);
    const [isRandom, setIsRandom] = useState(true);

    useEffect(() => {
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
            setHeight(event.target.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const controlAudio = (status) => {
        setStatus(status)
    }

    const audioProps = {
        audioType,
        status,
        audioSrc,
        timeslice: 1000,
        startCallback: e => {
            // console.log("succ start test", e);
        },
        pauseCallback: e => {
            // console.log("succ pause", e);
        },
        stopCallback: e => {
            setBlob(e)
            setAudioSrc(window.URL.createObjectURL(e))
            // console.log("succ stop", e);
        },
        onRecordCallback: e => {
            // console.log("recording", e);
        },
        errorCallback: err => {
            // console.log("error", err);
        },
        className: styles.audioTrackStyle,
    };

    const sendBlob = () => {
        setIsDownloadFile(true)
        axios.post(address + `patient/post?r=` + searchParams.get('r') + `&p=` + word["Id"] + `&w=` + width + `&h=` + height, blob)
            .then(res => {
                if (res.status === 200) {
                    setIsDownloadFile(false)
                    // alert("Успешная отправка")
                    setBlob("")
                    setAudioSrc("")

                    axios.post(address + `patient/phrase/get`, {
                        "Id": +searchParams.get('r'),
                        "Offset": currentWordID + 1
                    })
                        .then(res => {
                            if (res.status === 200) {
                                // console.log(res["data"])
                                setWord(res["data"])
                                setCurrentWordID(currentWordID + 1)
                                sessionStorage.setItem("currentWordID", currentWordID + 1);
                            }
                        }).catch((error) => {
                            axios.post(address + `patient/phrase/get`, {
                                "Id": +searchParams.get('r'),
                                "Offset": 0
                            })
                                .then(res => {
                                    if (res.status === 200) {
                                        alert("Записи закончились. Выбор слов начнётся с начала.")
                                        setWord(res["data"])
                                        setCurrentWordID(0)
                                        sessionStorage.setItem("currentWordID", 0);
                                    }
                                }).catch((error) => {
                                    alert("Ошибка получения слов")
                                });
                            // alert("Ошибка получения слов")
                        });

                }
            }).catch((error) => {
                setIsDownloadFile(false)
                alert("Ошибка отправки")
            });
        // setCurrentWordID(currentWordID + 1)
        // sessionStorage.setItem("currentWordID", currentWordID + 1);

    }


    const restart = () => {
        setCurrentWordID(0)
        axios.post(address + `patient/phrase/get`, {
            "Id": +searchParams.get('r'),
            "Offset": 0
        })
            .then(res => {
                if (res.status === 200) {
                    console.log(res["data"])
                    setWord(res["data"])
                    if (!sessionStorage.getItem("id")) {
                        sessionStorage.setItem("id", session);
                        sessionStorage.setItem("currentWordID", currentWordID);
                    } else {
                        setSession(sessionStorage.getItem("id"))
                    }
                }
            }).catch((error) => {
                console.log(error.message)
                if (error.message === "Request failed with status code 404") {

                } else {
                    alert("Ошибка получения слов")
                }

            });
    }

    useEffect(() => {
        axios.get(address + `patient/check?r=` + searchParams.get('r'))
            .then(res => {
                if (res.status === 200) {
                    setIsValid(true)
                    axios.post(address + `patient/progress/get`, {
                        "Id": +searchParams.get('r')
                    })
                        .then(res => {
                            setIsRandom(res["data"]['Offset'] === -1)
                            setCurrentWordID(res["data"]['Offset'] === -1 ? 0 : res["data"]['Offset'])
                            axios.post(address + `patient/phrase/get`, {
                                "Id": +searchParams.get('r'),
                                "Offset": res["data"]['Offset'] === -1 ? 0 : res["data"]['Offset']
                            })
                                .then(res => {
                                    if (res.status === 200) {
                                        // setOldWords([...res["data"]])
                                        // let mem = res["data"]
                                        // setWords(mem)
                                        console.log(res["data"])
                                        setWord(res["data"])
                                        if (!sessionStorage.getItem("id")) {
                                            sessionStorage.setItem("id", session);
                                            sessionStorage.setItem("currentWordID", currentWordID);
                                        } else {
                                            setSession(sessionStorage.getItem("id"))
                                        }
                                    }
                                }).catch((error) => {
                                    console.log(error.message)
                                    if (error.message === "Request failed with status code 404") {

                                    } else {
                                        alert("Ошибка получения слов")
                                    }

                                });
                        }).catch((error) => { });
                }
            }).catch((error) => {
                setIsValid(false)
            });
    }, [])

    // const changeSession = () => {
    //     let mem = generateRandomString(8)
    //     setSession(mem);
    //     setCurrentWordID(0);
    //     sessionStorage.setItem("currentWordID", 0);
    //     sessionStorage.setItem("id", mem);
    // }



    return (
        <div>
            <div>
                {isValid &&
                    <div>
                        {
                            <div>
                                {word["Phrase"] &&
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className={styles.shadowBox} style={!isDownloadFile ? { display: 'none' } : {}}>
                                            <div className={styles.sendText}>
                                                Происходит отправка...
                                            </div>
                                        </div>
                                        {!(!((sessionStorage.getItem("token") !== "" && sessionStorage.getItem("token") !== null && sessionStorage.getItem("token") !== undefined))) &&
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flex: 1 }} />
                                                <div className={styles.buttonCreateLink2} onClick={() => {
                                                    navigate('/link_page')
                                                }}>
                                                    Назад
                                                </div>
                                            </div>
                                        }
                                        {!isRandom &&
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flex: 1 }} />
                                                <div className={styles.buttonCreateLink2} style={{ width: 200, textAlign: 'center' }} onClick={() => restart()}>
                                                    Начать заново
                                                </div>
                                            </div>
                                        }
                                        <div style={{ alignSelf: "center" }}>
                                            <div className={styles.HeaderText}>{"Произнесите: " + word["Phrase"]} </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                                                <AudioAnalyser {...audioProps} >
                                                    <div>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                                                {!isRecording &&
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                                        controlAudio("recording");
                                                                        setIsRecording(true);
                                                                    }}>
                                                                        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <path d="M31.4733 200H23.8587C17.5379 199.983 11.4823 198.061 7.0127 194.652C2.54314 191.244 0.0222961 186.627 0 181.807V18.1936C0.0222961 13.3736 2.54314 8.7558 7.0127 5.34755C11.4823 1.93928 17.5379 0.0170019 23.8587 0H31.4733C39.9476 1.25305 47.7307 4.42291 53.809 9.09675L190.786 83.0325C196.686 87.533 200 93.636 200 100C200 106.364 196.686 112.467 190.786 116.967L189.77 117.677L53.809 190.903C47.7307 195.577 39.9476 198.747 31.4733 200ZM30.9656 12.9033H23.8587C22.018 12.9366 20.2659 13.5134 18.9718 14.5123C17.6777 15.5112 16.9425 16.854 16.9211 18.258V181.807C16.9211 183.21 17.6521 184.555 18.9531 185.548C20.2542 186.54 22.0187 187.097 23.8587 187.097H31.4733C35.4399 185.58 39.0896 183.623 42.3027 181.29L43.7411 180.645L179.11 107.613C181.553 105.578 182.909 102.92 182.909 100.162C182.909 97.4025 181.553 94.744 179.11 92.7095L42.3027 18.6451C38.9549 16.2771 35.1263 14.3381 30.9656 12.9033Z" fill="black" />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                }
                                                                {isRecording &&
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                                        controlAudio("inactive");
                                                                        setIsRecording(false)
                                                                    }}>
                                                                        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M40 200C29.3913 200 19.2172 196.488 11.7157 190.237C4.21427 183.986 0 175.507 0 166.667V33.3334C0 24.4928 4.21427 16.0143 11.7157 9.7631C19.2172 3.51189 29.3913 0 40 0C50.599 0.0470712 60.7555 3.54626 68.3 9.75C75.7445 16.0371 79.9435 24.5009 80 33.3334V166.667C80 175.507 75.7855 183.986 68.2845 190.237C60.783 196.488 50.6085 200 40 200ZM40 16.6666C34.6956 16.6666 29.6086 18.4226 25.8578 21.5482C22.1071 24.6738 20 28.913 20 33.3334V166.667C20 171.087 22.1071 175.326 25.8578 178.452C29.6086 181.577 34.6956 183.333 40 183.333C45.3044 183.333 50.3915 181.577 54.142 178.452C57.893 175.326 60 171.087 60 166.667V33.3334C60 28.913 57.893 24.6738 54.142 21.5482C50.3915 18.4226 45.3044 16.6666 40 16.6666Z" fill="black" /><path d="M160 200C149.392 200 139.217 196.488 131.715 190.237C124.214 183.986 120 175.507 120 166.667V33.3334C120 24.4928 124.214 16.0143 131.715 9.7631C139.217 3.51189 149.392 0 160 0C170.609 0 180.783 3.51189 188.284 9.7631C195.785 16.0143 200 24.4928 200 33.3334V166.667C200 175.507 195.785 183.986 188.284 190.237C180.783 196.488 170.609 200 160 200ZM160 16.6666C154.695 16.6666 149.609 18.4226 145.858 21.5482C142.107 24.6738 140 28.913 140 33.3334V166.667C140 171.087 142.107 175.326 145.858 178.452C149.609 181.577 154.695 183.333 160 183.333C165.305 183.333 170.391 181.577 174.142 178.452C177.893 175.326 180 171.087 180 166.667V33.3334C180 28.913 177.893 24.6738 174.142 21.5482C170.391 18.4226 165.305 16.6666 160 16.6666Z" fill="black" />
                                                                        </svg>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {blob &&
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ flex: 1 }}>
                                                                    Можно прослушать запись
                                                                </div>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => { setBlob(""); setAudioSrc(""); }}>
                                                                    <svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.51562 9.33203H9.39454V24.3633H7.51562V9.33203Z" fill="#134563" /><path d="M11.2734 9.33203H13.1524V24.3633H11.2734V9.33203Z" fill="#134563" /><path d="M15.0312 9.33203H16.9102V24.3633H15.0312V9.33203Z" fill="#134563" /><path d="M0 3.69531H24.4259V5.57423H0V3.69531Z" fill="#134563" /><path d="M16.8474 4.63465H15.0938V2.75574C15.0938 2.19206 14.6554 1.75366 14.0917 1.75366H10.3339C9.77019 1.75366 9.33177 2.19206 9.33177 2.75574V4.63465H7.57812V2.75574C7.57812 1.25261 8.83073 0 10.3339 0H14.0917C15.5948 0 16.8474 1.25261 16.8474 2.75574V4.63465Z" fill="#134563" />
                                                                        <path d="M17.8497 29.9997H6.57619C5.07306 29.9997 3.75782 28.7471 3.63256 27.244L1.87891 4.69704L3.75782 4.57178L5.51148 27.1188C5.57411 27.6824 6.07515 28.1208 6.57619 28.1208H17.8497C18.4134 28.1208 18.9144 27.6198 18.9144 27.1188L20.6681 4.57178L22.547 4.69704L20.7933 27.244C20.6681 28.8098 19.3528 29.9997 17.8497 29.9997Z" fill="#134563" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </AudioAnalyser>
                                            </div>
                                            <div >
                                                {blob !== "" &&
                                                    <div style={{ marginTop: 40 }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }} >
                                                            <div className={styles.buttonStyle} style={isDownloadFile ? { cursor: 'wait' } : {}} onClick={() => sendBlob()}>
                                                                <div>Отправить</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {!word["Phrase"] &&
                                    <div>
                                        {!(!((sessionStorage.getItem("token") !== "" && sessionStorage.getItem("token") !== null && sessionStorage.getItem("token") !== undefined))) &&
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flex: 1 }} />
                                                <div className={styles.buttonCreateLink2} onClick={() => {
                                                    navigate('/link_page')
                                                }}>
                                                    Назад
                                                </div>
                                            </div>
                                        }
                                        <div className={styles.notText}>
                                            Все слова произнесены
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                                            {/* <div className={styles.buttonStyle} onClick={() => changeSession()}>
                                                <div>Следующий пользователь</div>
                                            </div> */}
                                        </div>
                                    </div>
                                }
                            </div>}
                    </div>
                }
                {!isValid &&
                    <div className={styles.notText}>
                        Такой ссылки не существует
                    </div>
                }
            </div>
        </div>
    );
}

function generateRandomString(length) {
    return Math.random().toString(36).substring(2, length + 2);
}
export default CollectorPage;
